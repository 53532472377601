<template>

<div>
  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row gx-lg-0 gy-10 align-items-center">


        <div class="col-lg-12">
          <h2 class="display-4 mb-3">{{title}}</h2>
          <p class="lead fs-lg">{{sub_title}}</p>
          <p class="mb-0">{{contents}}</p>
        </div>
        <!--/column -->
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 col-xxl-12 mx-auto">
          <div class="row  justify-content-start counter-wrapper gy-6">

            <div class="col-md-6 col-lg-3"  v-for="(item,index) in facts" :key="index" >
              <div class="card" style="height:100%">
                <div class="card-body">
                  <div class="d-flex flex-row align-items-center">

                    <div>
                      <h3 class="  mb-1">{{item.counter}}</h3>
                      <hr class="my-1 mx-n6"/>
                      <p class="mb-0" style="font-size:80%">{{item.caption}} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>

</div>



</template>


<script>


export default {
  name: 'account',
  components: {},
  props: {
    title : String,
    sub_title: String,
    contents: String,
      facts: [],
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
