<template>
<div>
  <facts1

title = "Les frais"
sub_title ="Un ecosytème décentralisé, désintermédié et à très faibles coût."
contents ="Une transparence totale sur l'intégralité des frais sur la blockchain Stellar."
:facts =  facts
  />
</div>
</template>


<script>
import facts1 from "@/components/facts1.vue";



export default
{
  name: 'Tarifs',
  components:
  {
    facts1
  },
  props:
  {},
  data: () => (
  {



    facts :[
    {counter:"de 0.01 à 0.02 €", caption:"Achat, Vente ou Transfert de tokens"},
    {counter:"de 0.01 à 0.02 €", caption:"Tout autre type de transaction"},
    {counter:"0.3%", caption:"Achat ou vente via AMM Stellar (Automated Market Maker) "},
    {counter:"Sans frais", caption:"Virement Sepa en euro"},
    {counter:"0.35 € + 1.6%", caption:"Virement par Carte Bancaire de la zone Euro"},
    {counter:"0.35 € + 3.4%", caption:"Virement par Carte Bancaire hors zone Euro"},
    {counter:"0.35 € + 1.6%", caption:"Virement Paypal"},
    {counter:"0.3% + gas Ether", caption:"Achat ou vente via AMM Uniswap (Automated Market Maker)  "},
    {counter:"0.3% + gas Ether", caption:"Conversion Stellar ERC-20"},
    ] ,
  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed:
  {},
  methods:
  {},
  watch:
  {}
}
</script>

<style scoped>
</style>
